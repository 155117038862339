.App {
  height: 100%;
}

.esNavBar {
  background: rgba(var(--es-primary-color), var(--es-transparency));
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Intro-Text {
  font-family: Roboto;
  font-size: 22px;
  text-align: center;
  color: black;
}

.Intro-Video {
  border-style: solid;
  border-width: 20px;
  border-color: rgba(var(--es-primary-color), var(--es-transparency));
  /*background: #000000;*/
}

.App-Window {
  height: 100vh;
}

.roundedBox {
  border-style: solid;
  border-radius: 10px;
  border-width: thin;
}

.Full-Height {
  height: 100%;
}
