.widgetHeader {
    font-family: RobotoBold;
    font-size: 20px;
}

.statuswidgetIcon {
    font-family: RobotoBold;
    font-size: 16px;
}

.statuswidgetLabel {
    font-family: RobotoBold;
}

.es-maincolumn {
    height: 90%;
}

.commandcenter {
    min-height: 40%;
}

.txhistory {
    min-height: 45%;
}

.phasemeter {
    min-height: 15%;
}

.ReactVirtualized__Grid {
    outline: none;
}

.txhistoryfont {
    font-size: 18px;
}

.txHistoryRow {
    border-top: solid;
    border-left: solid;
    border-width: 1px;
    background: rgba(var(--es-primary-color), var(--es-transparency));
}

.erdstallwidget {
    min-width: 800px;
    max-width: 800px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(var(--es-tertiary-color), var(--es-transparency));
    background: rgba(var(--es-primary-color), var(--es-transparency));
    margin-left: .5em;
    margin-right: .5em;
}

.widgethr {
    color: white;
    border-color: white;
    height: .5;
}

.phasebox {
    border-style: solid;
    border-color: rgba(var(--es-tertiary-color), var(--es-transparency));
    border-width: 1px;
    border-radius: 10px;
    background-color: rgba(var(--es-secondary-color), var(--es-transparency));
}

.es-phasemeter {
    height: 30px;
    background: rgb(var(--es-tertiary-color));
    color: rgb(var(--es-secondary-color));
}

.es-phaselabel {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: -35px;
    z-index: 10;
}

.es-phasebox-row {
    height: 0;
}

.phaseboxVal {
    font-family: RobotoBold;
    font-size: 26px;
}

.es-circular-background, .es-circular-foreground {
    position: absolute;
    top: 4px;
    right: 10px;
}

.es-history-table-row {
    height: 76%;
}

.es-tx-table {
    height: 100%;
    width: 100%;
}

.es-history-container {
    height: 100%;
    width: 100%;
}

.es-href {
    color: rgb(255, 255, 204);
}

.es-href:hover {
    color: rgb(255, 255, 204);
}

.es-history-header:hover {
    color: rgb(255, 255, 255);
}

.es-txhistory-button {
    background: rgba(0, 0, 0, 0);
    border: none;
}

.es-status-selected {
    background: rgb(167, 170, 173);
}

.es-status-unselected {
    background: rgb(233, 236, 239);
}

.es-pb-selected {
    background: rgb(var(--es-primary-color));
}

.es-pb-unselected {
    background: rgb(var(--es-secondary-color));
}

.es-onchainbal, .es-offchainbal, .es-withdrawableBals {
    font-size: 13px;
}

.phaseboxSymbol {
    font-family: RobotoBold;
    font-size: 24px;
    background: rgb(var(--es-tertiary-color));
}
