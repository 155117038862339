body > #root {
  height: 100vh;
  min-width: 1150px;
  min-height: 750px;
}

#root > div.container-fluid, #root > div.container, .esbox {
  width: max-content;
  background-color: rgba(255,255,255, var(--es-transparency));
  padding-top: 1em;
  padding-bottom: 1em;
  box-shadow: rgba(0,0,0,.45) 0px 15px 15px;
}

:root {
  --es-background: 255, 255, 255;
  --es-primary-color: 15, 58, 95;
  --es-secondary-color: 62, 123, 174;
  --es-tertiary-color:  23, 97, 160;
  --es-primary-font-fg: 255, 255, 255;
  --es-transparency: 0.666;
}

.plane {
  box-shadow: rgba(0,0,0,.5) 0px 6px 6px;
  margin-bottom: 6px;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(255,255,255, var(--es-transparency));
}

body::before {
      content: "";
      background-image: url(imgs/tee-1740871_1920.jpg);
      background-size: cover;
      position: fixed;
      z-index: -10000;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.75;
}

.White-Text {
  font-color: #ffffff;
  color: white;
}

.Black-Text {
  font-color: #000000;
  color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{
  font-family: "Roboto";
  src: local("Roboto"), url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face{
  font-family: "RobotoBold";
  src: local("RobotoBold"), url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}
